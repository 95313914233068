import axios from "axios";
import state from "../store/store";
import { setErrorsData } from "../store/errors/errorsActions";
import { showRefreshPopup } from "../store/toggles/toggleStateActions";

import { BASE_URL } from "../config/api.config";
import axiosRetry from "axios-retry";

const instance = axios.create({ baseURL: BASE_URL });

const NB_RETRIES = 5;

const retryDelayFunc = (retryCount) => {
  return retryCount * 2000;
};

const retryConditionFunc = (error) => {
  const status = error?.response?.status;

  return !status || status >= 500 || isInGame502(error);
  // return isInGame502(error);
};

const isInGame = (error) => {
  let isGameUrl = /\/lobby\/[^\/]+\/game\/.+/.test(error.config.url);
  let isPost = error.config.method.toLowerCase() === "post";

  return isPost && isGameUrl;
};

const isInGame502 = (error) => {
  let is502 = error?.response?.status === 502 || ! error.response;

  return isInGame(error) && is502;
};

const isShowErrorPage = (error) => {
  const showErrorPage = ! error?.response?.config?.headers?.hideErrorPage;
  const disableErrorByUrl = [
    '/broadcasting/auth',
    '/feedback/connection-lost'
  ];

  return showErrorPage 
    && disableErrorByUrl.indexOf(error?.config?.url) < 0
    && !! error?.response?.status
}

const isInGame400 = (error) => {
  let is400 = error?.response?.status === 400;

  return isInGame(error) && is400;
};

const isValidResponse = (response) => {
  return response?.status >= 200 && response?.status <= 600
}

const processAxiosError = (error) => {
  
  if (isInGame502(error)) {
    state.dispatch(showRefreshPopup());
  } else if (isInGame400(error)) {
    // TODO: Process 400 response from back
  } else if (isShowErrorPage(error)) {
    state.dispatch(
      setErrorsData({
        errorOccured: true,
        statusCode: error?.response?.status,
        errorMessage: error?.response?.statusText ?? 'internal error',
      })
    );
    throw `Api error!!!`;
  } else {
    throw `Api error!!!`;   
  }

  return Promise.reject(error);
}

const axiosRetryConfig = {
  retries: NB_RETRIES,
  retryDelay: retryDelayFunc,
  retryCondition: retryConditionFunc,
};

axiosRetry(instance, axiosRetryConfig);

instance.interceptors.request.use(async (req) => {
  const token = localStorage.getItem("access_token");

  req.headers = {
    ...req.headers,
    Authorization: "Bearer " + token,
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  return req;
});

instance.interceptors.response.use(
  (response) => {

    state.dispatch(
      setErrorsData({
        errorOccured: false,
        statusCode: 0,
        errorMessage: '',
      })
    );

    return isValidResponse(response) ? response.data : response;
  },
  (error) => {
    return processAxiosError(error);
  }
);

const api = instance;
export { api };

export default instance;
